import {
    handleQueryResolve
} from '../utils'

export default function(PatientID) {
    let where
    let attrs
    if (Array.isArray(PatientID)) {
        where = [`DBA.Patient.Id IN (${PatientID.join(',')})`]
    } else {
        attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID, // to support like
        }]
        where = ['DBA.Patient.Id = @PatientID']
    }
    console.log('where', where)
    const query = `
    SELECT "DBA"."Subscriber"."Rank" AS "PlanOrder",
    ("DBA"."ThirdParty"."Name"+ '-' +"DBA"."thirdpartydefaultvalues"."billingcode") AS "PlanCode",
    "DBA"."Subscription"."GroupId" AS "GroupID",
    "DBA"."Subscription"."ClientId" AS "PlanID",
    "DBA"."Subscription"."CarrierId" AS "CarrierID",
    "DBA"."Subscriber"."Relationship" AS "RelationShip",
    "DBA"."Subscriber"."activeflag" as "Inactive",
    "DBA"."thirdpartydefaultvalues"."billingcode" as SmartCard
    FROM ( ( ( "DBA"."Patient" JOIN "DBA"."Subscriber" ON "DBA"."Patient"."Id" = "DBA"."Subscriber"."PatientId" ) JOIN "DBA"."Subscription" ON "DBA"."Subscriber"."SubscriptionId" = "DBA"."Subscription"."SubscriptionId" ) INNER JOIN "DBA"."ThirdParty" ON "DBA"."Subscriber"."Plan_ThirdPartyId" = "DBA"."ThirdParty"."Id" ) INNER JOIN "DBA"."thirdpartydefaultvalues" ON STRING( "DBA"."thirdpartydefaultvalues"."thirdpartyid", "DBA"."thirdpartydefaultvalues"."planid" ) = STRING( "DBA"."Subscription"."Plan_ThirdPartyId", "DBA"."Subscription"."PlanId" )
    WHERE ${where}
    GROUP BY ( "DBA"."Subscriber"."Rank", "DBA"."ThirdParty"."Name", "DBA"."Subscription"."GroupId", "DBA"."Subscription"."CarrierId", "DBA"."Subscriber"."Relationship", "DBA"."Subscriber"."activeflag", "DBA"."Patient"."Id", "DBA"."Subscription"."ClientId", "DBA"."thirdpartydefaultvalues"."billingcode" )
    ORDER BY "DBA"."Subscriber"."Rank" ASC
          `

    // for nexxsys is it is an ative flag and there if it is 0, then it is inactive and if it is 1 then it is active. keep that in mind in the front end at plans. vue
    console.log(query)

    return this.query(query, attrs).then(handleQueryResolve)
}